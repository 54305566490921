import React, { useEffect, useState } from "react"
import axios from "axios"
import Cookies from "js-cookie"
import styles from "../../styles/page_casaCoronaForm.module.scss"
import tick from "../../../assets/images/icons/tickform.svg"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import terminos from "../../../static/TyC_Casa_Corona_febrero_2022.pdf"
import privacidad from "../../../static/PP_AO.pdf"
import FooterCasaCorona from "../FooterCasaCorona"
import AgeGate2 from "../agegate"
import formImage from "../../images/corona-sunset-form.jpeg"

// Form Items
const CoronaSunsetForm = ({ location }) => {
  const fetchURL =
    "https://wacoronabe-prd.azurewebsites.net/api/corona-sunset-form"
  const [age, setAge] = useState(true)
  // const sessions = data.allSessions.edges
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [lastName, setLastName] = useState("")
  const [province, setProvince] = useState("")
  const [preference_question, setPreference_question] = useState("")
  const [terms, setTerms] = useState(false)
  const [dateOfBirth, setDateOfBirth] = useState("")

  const [loading, setLoading] = useState(false)
  const [approved, setApproved] = useState(false)
  const [isMobile1, setIsMobile1] = useState(false)
  const [error, setError] = useState(false)
  const [step, setStep] = useState({})

  useEffect(() => {
    if (step.number) {
      window.dataLayer.push({
        event: "GAevent",
        event_category: "Form",
        event_action: `Paso ${step.number}`,
        event_label: `${step.name}`,
        interaction: "True",
        component_name: `form_${step.name}`,
      })
    }
  }, [step])

  const formatDate = (value) => {
    const cleanValue = value.replace(/\D/g, "") // Remove non-digit characters
    let formattedDate = ""

    if (cleanValue.length <= 2) {
      formattedDate = cleanValue
    } else if (cleanValue.length <= 4) {
      formattedDate = `${cleanValue.slice(0, 2)}/${cleanValue.slice(2)}`
    } else {
      formattedDate = `${cleanValue.slice(0, 2)}/${cleanValue.slice(
        2,
        4
      )}/${cleanValue.slice(4, 8)}`
    }

    return formattedDate
  }

  const isDateValid = (dateString) => {
    const date = new Date()
    const year = date.getFullYear()
    if (dateString.length < 10) return false
    if (Number(dateString.split("/")[0]) > 31) return false
    if (Number(dateString.split("/")[1]) > 12) return false
    if (Number(dateString.split("/")[2]) >= year) return false
    return true
  }

  const switchTYP = () => {
    return (
      <>
        <h4>
          Ya estas participando
          <br />
          para vivir una experiencia única con Corona.
        </h4>
      </>
    )
  }

  useEffect(() => {
    let local = sessionStorage.getItem("age")
    if (local) setAge(false)
    window.innerWidth <= 800 ? setIsMobile1(true) : setIsMobile1(false)
  }, [])

  const isFormComplete = () => {
    if (name && lastName && email && province && terms && preference_question) {
      return true
    }
    return false
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (
      name !== "" &&
      lastName !== "" &&
      email !== "" &&
      province !== "" &&
      preference_question !== "" &&
      dateOfBirth !== ""
    ) {
      if (!isDateValid(dateOfBirth)) {
        setError({ message: "Fecha de nacimiento invalida" })
        setTimeout(() => {
          setError(false)
        }, 2000)
        window.dataLayer.push({
            event: "GAevent",
            event_category: "Form",
            event_action: `Error`,
            event_label: `Fecha de nacimiento invalida`,
            interaction: "True",
            component_name: "boton_participa",
        });

        return
      }
      setLoading(true)

      const emailRes = await axios.post(fetchURL + "/check-email", { email })
      if (emailRes.data?.isRegistered) {
        window.dataLayer.push({
          event: "GAevent",
          event_category: "Form",
          event_action: `Error`,
          event_label: `Email ya registrado`,
          interaction: "True",
          component_name: "boton_participa",
        });
        setLoading(false)
        setError({ message: `el email: ${email} ya esta registrado` })
        setTimeout(() => {
          setError(false)
        }, 2000)
        return
      }

      let td = Cookies.get("_td")
      let obj = {
        name,
        last_name: lastName,
        email,
        dni: "",
        state: province,
        preference_question,
        date_of_birth: dateOfBirth,
        td,
      }

      fetch(fetchURL, {
        method: "POST",
        body: JSON.stringify(obj),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        window.dataLayer.push({
          event: "GAevent",
          event_category: "Form",
          event_action: `Success`,
          event_label: ``,
          interaction: "True",
          component_name: "boton_participa",
          userUid: td,
        });
      })

      setApproved(true)
      setLoading(false)
    }
  }

  const handleTerms = () => {
    setStep({ number: 7, name: "TyC" })
    return setTerms(!terms)
  }

  return !age ? (
    approved ? (
      <div className={styles.green}>
        <img src={tick} />
        {switchTYP()}
      </div>
    ) : isMobile1 ? (
      <>
        <div>
          <div className={styles.imageContainer}>
            <div className={styles.formImagesCoronaSunset}></div>
          </div>
          <form onSubmit={handleSubmit} className={styles.formContainer}>
            <h3>Nombre*</h3>
            <div className={styles.bordeInsc}>
              <div className="col px-0">
                <input
                  onBlur={() => setStep({ number: 1, name: "Nombre" })}
                  requiredx
                  type="text"
                  className={styles.casaCoronaInscription}
                  placeholder="Nombre "
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
            </div>
            <h3>Apellido*</h3>
            <div className={styles.bordeInsc}>
              <div className="col px-0">
                <input
                  onBlur={() => setStep({ number: 2, name: "Apellido" })}
                  required
                  type="text"
                  className={styles.casaCoronaInscription}
                  placeholder="Apellido"
                  onChange={(e) => setLastName(e.target.value)}
                ></input>
              </div>
            </div>

            <h3>Email*</h3>
            <div className={styles.bordeInsc}>
              <div className="col px-0">
                <input
                  onBlur={() => setStep({ number: 3, name: "Email" })}
                  type="email"
                  required
                  className={styles.casaCoronaInscription}
                  placeholder="E-mail"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
            </div>

            <h3>Fecha de nacimiento*</h3>
            <div className={styles.bordeInsc}>
              <div className="col px-0">
                <input
                  onBlur={() =>
                    setStep({ number: 4, name: "Fecha de nacimiento" })
                  }
                  required
                  className={styles.casaCoronaInscription}
                  placeholder="DD/MM/AAAA"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(formatDate(e.target.value))}
                ></input>
              </div>
            </div>

            <h3>Provincia*</h3>
            <select
              onClick={() => setStep({ number: 5, name: "Provincia" })}
              required
              onChange={(e) => setProvince(e.target.value)}
              id="first-choice"
              className="selectProvinciasM"
            >
              <option disabled selected value="">
                Provincia
              </option>
              <option value="BsAs CABA">BsAs CABA</option>
              <option value="Buenos Aires">BsAs GBA Norte</option>
              <option value="Buenos Aires">BsAs GBA Sur</option>
              <option value="Buenos Aires">BsAs GBA Oest</option>
              <option value="Buenos Aires">BsAs Fuera de AMBA</option>
              <option value="Catamarca">Catamarca</option>
              <option value="Chaco">Chaco</option>
              <option value="Chubut">Chubut</option>
              <option value="Córdoba">Córdoba</option>
              <option value="Corrientes">Corrientes</option>
              <option value="Entre Ríos">Entre Ríos</option>
              <option value="Formosa">Formosa</option>
              <option value="Jujuy">Jujuy</option>
              <option value="La Rioja">La Rioja</option>
              <option value="Mendoza">Mendoza</option>
              <option value="Misiones">Misiones</option>
              <option value="Neuquén">Neuquén</option>
              <option value="Río Negro">Jujuy</option>
              <option value="Salta">Salta</option>
              <option value="San Juan">San Juan</option>
              <option value="San Luis">San Luis</option>
              <option value="Santa Cruz">Santa Cruz</option>
              <option value="Santa Fe">Santa Fe</option>
              <option value="Santiago del Estero">Santiago del Estero</option>
              <option value="Tierra del Fuego">Tierra del Fuego</option>
              <option value="Tucumán">Tucumán</option>
            </select>
            <h3>Ocasión de consumo*</h3>
            <select
              onClick={() => {
                setStep({ number: 6, name: "Ocasión de consumo" })
              }}
              required
              onChange={(e) => setPreference_question(e.target.value)}
              id="first-choice"
              className="selectProvinciasM"
            >
              <option disabled selected value="">
                Ocasión
              </option>
              <option value="Juntadas con amigxs en una casa">
                Juntadas con amigxs en una casa
              </option>
              <option value="En las previas">En las previas</option>
              <option value="En fiestas/salidas">En fiestas/salidas</option>
              <option value="Tranqui en casa">Tranqui en casa</option>
              <option value="No tomo cerveza">No tomo cerveza</option>
              <option value="Otro">Otro</option>
            </select>
            <RadioGroup
              aria-label="gender"
              className={styles.inputradio}
              name="termsAndConditions"
              onChange={handleTerms}
            >
              <FormControlLabel
                value="female"
                color="default"
                className={styles.radio}
                control={
                  <Radio
                    value="accept"
                    color="grey"
                    name="radio"
                    inputProps={{ "aria-label": "D", color: "grey" }}
                  />
                }
              />
              <span className={styles.textbases}>
                He leído y acepto los{" "}
                <a
                  className={styles.acolor}
                  href={terminos}
                  download
                  target="_blank"
                >
                  términos de uso
                </a>
                ,{" "}
                <a
                  className={styles.acolor}
                  href={privacidad}
                  download
                  target="_blank"
                >
                  políticas de privacidad
                </a>{" "}
                y activaciones de marketing.
              </span>
            </RadioGroup>

            <div className={styles.btnSubmit}>
              <div className="col px-0">
                {error && (
                  <div class={styles.errorMessage}>{error.message}</div>
                )}
                <button
                  className={
                    !isFormComplete() || loading
                      ? styles.btnDisabled
                      : styles.btnEnabled
                  }
                  disabled={!isFormComplete() || loading}
                >
                  PARTICIPÁ
                </button>
              </div>
            </div>
          </form>
          <FooterCasaCorona isMobile={true} />
        </div>
      </>
    ) : (
      <>
        <div
          style={{ display: "flex", marginBottom: "-2px", minHeight: "100vh" }}
        >
          <div className={styles.formImageDCoronaSunset}></div>

          <div style={{ width: "60%" }}>
            <form onSubmit={handleSubmit} className={styles.formContainerD}>
              <h3>Nombre*</h3>
              <div className={styles.bordeInscD}>
                <div className="col px-0">
                  <input
                    onBlur={() => setStep({ number: 1, name: "Nombre" })}
                    required
                    type="text"
                    className={styles.casaCoronaInscriptionD}
                    placeholder="Nombre "
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                </div>
              </div>
              <h3>Apellido*</h3>
              <div className={styles.bordeInscD}>
                <div className="col px-0">
                  <input
                    onBlur={() => setStep({ number: 2, name: "Apellido" })}
                    required
                    type="text"
                    className={styles.casaCoronaInscriptionD}
                    placeholder="Apellido"
                    onChange={(e) => setLastName(e.target.value)}
                  ></input>
                </div>
              </div>

              <h3>Email*</h3>
              <div className={styles.bordeInscD}>
                <div className="col px-0">
                  <input
                    onBlur={() => setStep({ number: 3, name: "Email" })}
                    type="email"
                    required
                    className={styles.casaCoronaInscriptionD}
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </div>
              </div>

              <h3>Fecha de nacimiento*</h3>
              <div className={styles.bordeInscD}>
                <div className="col px-0">
                  <input
                    onBlur={() =>
                      setStep({ number: 4, name: "Fecha de nacimiento" })
                    }
                    required
                    className={styles.casaCoronaInscriptionD}
                    placeholder="DD/MM/AAAA"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(formatDate(e.target.value))}
                  ></input>
                </div>
              </div>

              <h3>Provincia*</h3>
              <select
                onClick={() => setStep({ number: 5, name: "Provincia" })}
                required
                onChange={(e) => setProvince(e.target.value)}
                id="first-choice"
                className="selectProvinciasM"
              >
                <option disabled selected value="">
                  Provincia
                </option>
                <option value="BsAs CABA">BsAs CABA</option>
                <option value="Buenos Aires">BsAs GBA Norte</option>
                <option value="Buenos Aires">BsAs GBA Sur</option>
                <option value="Buenos Aires">BsAs GBA Oest</option>
                <option value="Buenos Aires">BsAs Fuera de AMBA</option>
                <option value="Catamarca">Catamarca</option>
                <option value="Chaco">Chaco</option>
                <option value="Chubut">Chubut</option>
                <option value="Córdoba">Córdoba</option>
                <option value="Corrientes">Corrientes</option>
                <option value="Entre Ríos">Entre Ríos</option>
                <option value="Formosa">Formosa</option>
                <option value="Jujuy">Jujuy</option>
                <option value="La Rioja">La Rioja</option>
                <option value="Mendoza">Mendoza</option>
                <option value="Misiones">Misiones</option>
                <option value="Neuquén">Neuquén</option>
                <option value="Río Negro">Jujuy</option>
                <option value="Salta">Salta</option>
                <option value="San Juan">San Juan</option>
                <option value="San Luis">San Luis</option>
                <option value="Santa Cruz">Santa Cruz</option>
                <option value="Santa Fe">Santa Fe</option>
                <option value="Santiago del Estero">Santiago del Estero</option>
                <option value="Tierra del Fuego">Tierra del Fuego</option>
                <option value="Tucumán">Tucumán</option>
              </select>

              <h3>Ocasión de consumo*</h3>
              <select
                onClick={() =>
                  setStep({ number: 6, name: "Ocasión de consumo" })
                }
                required
                onChange={(e) => setPreference_question(e.target.value)}
                id="first-choice"
                className="selectProvinciasM"
              >
                <option disabled selected value="">
                  Ocasión
                </option>
                <option value="Juntadas con amigxs en una casa">
                  Juntadas con amigxs en una casa
                </option>
                <option value="En las previas">En las previas</option>
                <option value="En fiestas/salidas">En fiestas/salidas</option>
                <option value="Tranqui en casa">Tranqui en casa</option>
                <option value="No tomo cerveza">No tomo cerveza</option>
                <option value="Otro">Otro</option>
              </select>
              <RadioGroup
                aria-label="gender"
                className={styles.inputradio}
                name="termsAndConditions"
                onChange={handleTerms}
              >
                <FormControlLabel
                  value="female"
                  color="default"
                  className={styles.radio}
                  control={
                    <Radio
                      value="accept"
                      color="grey"
                      name="radio"
                      inputProps={{ "aria-label": "D", color: "grey" }}
                    />
                  }
                />
                <span className={styles.textbases}>
                  He leído y acepto los{" "}
                  <a
                    className={styles.acolor}
                    href={terminos}
                    download
                    target="_blank"
                  >
                    términos de uso
                  </a>
                  ,{" "}
                  <a
                    className={styles.acolor}
                    href={privacidad}
                    download
                    target="_blank"
                  >
                    políticas de privacidad
                  </a>{" "}
                  y activaciones de marketing.
                </span>
              </RadioGroup>

              <div className={styles.btnSubmitD}>
                <div className="col px-0">
                  {error && (
                    <div class={styles.errorMessage}>{error.message}</div>
                  )}
                  <button
                    className={
                      !isFormComplete() || loading
                        ? styles.btnDisabled
                        : styles.btnEnabled
                    }
                    disabled={!isFormComplete() || loading}
                  >
                    PARTICIPÁ
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <FooterCasaCorona isMobile={false} />
      </>
    )
  ) : (
    <AgeGate2 setAge={setAge} />
  )
}

export default CoronaSunsetForm
